<template>
  <div id="page-user-view">
    <div
      v-if="user"
      id="user-data"
    >
      <b-row>
        <b-col>
          <b-card>
<!-- 
            <b-card-header>
              <h4>
               Setting
              </h4>
          </b-card-header> -->
             <b-row>
             	<b-col cols="10">
             		Show Data To Upline User
             	</b-col>
             	<b-col cols="2">
             		 <toggle-button
                  :value="(user.show_to_upline ==1)?true:false"
                  :sync="true"
                  :labels="{checked: 'Active', unchecked: 'Inactive'}"
                  :width="70"
                  @change="changeStatus(user.show_to_upline)"
                />
             	</b-col>
             </b-row>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import {
  BRow, BCol, BButton, BCard, BCardHeader, BCardBody,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UserService from '../../services/user.service'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BCardBody,
  },
   props: ["user"],
  data() {
    return {
      status: '',
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
  },
  created() {
    //this.getUserInfo()
  },
  methods: {
    changeStatus(status) {
    	this.user.show_to_upline = !status
      UserService.changeStatus({'status':!status}).then(
        response => {
          //this.user_data = response
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Status Change',
                text: response.message,
                icon: 'UserIcon',
                variant: 'success',
              },
            })
        },
        error => {},
      )
    },
  },
}

</script>
