<template>
  <div>
    <b-card>

      <!-- form -->
      <b-form>

        <b-alert
          v-model="errorShow"
          variant="danger"
          dismissible
        >
          <div class="alert-body">
            <span>{{ message }}</span>
          </div>
        </b-alert>
        <validation-observer ref="observer">
          <b-row>

            <!-- old password -->
            <b-col md="6">
              <b-form-group
                label="Mobile No"
                label-for="mobile"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="confirmation"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="mobile"
                      v-model="user.mobile"
                      disabled
                      name="mobile"
                      placeholder="Mobile Number"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        icon="PhoneIcon"
                        class="cursor-pointer"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <span>{{ errors[0] }}</span>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label-for="new_mob_no"
                label="New Mobile No"
              >
                <validation-provider
                  ref="mobileprovider"
                  #default="{ errors }"
                  name="Mobile Number"
                  :rules="`required|numeric|startWith|min:11|max:12|confrimMobile:${user.mobile}|uniqueMobile`"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="new_mob_no"
                      v-model="mobile"
                      name="mobile"
                      placeholder="Enter Mobile No"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        icon="PhoneIcon"
                        class="cursor-pointer"
                      />
                    </b-input-group-append>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label-for="otp"
                label="OTP"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required|min:6|validOtp"
                  name="OTP"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="otp"
                      v-model="otp"
                      type="number"
                      name="otp"
                      placeholder="Enter OTP"
                    />

                  </b-input-group>
                  <small
                    v-if="errors[0]!='OTP is not valid.'"
                    class="text-danger"
                  >{{ errors[0] }}</small>
                  <small
                    v-if="validMessge !='' && errors[0]=='OTP is not valid.'"
                    class="text-danger"
                  >{{ validMessge }}</small>
                  <small
                    v-if="validtrueMessge !=''"
                    class="text-success"
                  >{{ validtrueMessge }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6 mt-2">
              <b-button
                v-if="stopTrying"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :disabled="loading"
                variant="outline-success"
                class=" mr-1"
                @click="requetOTP"
              >
                <b-spinner
                  v-show="loading"
                  small
                />
                Request OTP
              </b-button>
              <countdown
                v-if="!stopTrying && showTimer"
                :time="waitingTime"
                class="mr-1"
                @end="handleCountdownEnd"
              >
                <template slot-scope="props">
                  {{ props.minutes }}:{{ props.seconds }}
                </template>
              </countdown>
            </b-col>
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-1 mr-1"
                @click="save_changes"
              >
                Change
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-form>
    </b-card>
  </div>
</template>
<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BInputGroup,
  BInputGroupAppend,
  BAlert,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider, ValidationObserver, extend,
} from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SecureLS from 'secure-ls'
import * as rules from 'vee-validate/dist/rules'
import VueCountdown from '@chenfengyuan/vue-countdown'
import { messages } from 'vee-validate/dist/locale/en.json'
import UserService from '../../services/user.service'

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule], // assign message
  })
})
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BAlert,
    BInputGroup,
    BSpinner,
    BInputGroupAppend,
    countdown: VueCountdown,
  },
  directives: {
    Ripple,
  },
  props: ["user"],
  data() {
    return {
      otp: '',
      mobile: '',
      stopTrying: 1,
      waitingTime: 0,
      showTimer: 0,
      errorShow: false,
      message: '',
      loading: false,
      validMessge: '',
      validtrueMessge: '',
      success: false,
    }
  },
  mounted() {
    const isUniqueMobile = value => UserService.validatePhone({ mobile: value }).then(
      response => {
        this.otp = ''
        this.stopTrying = true
        this.success = false
        return {
          valid: response.mobile_valid,
          data: {
            message: response.mobile_message,
          },
        }
      },
      error => {},
    )
    const validateOtp = value => {
      const ls = new SecureLS({
        encodingType: 'aes',
        isCompression: false,
      })

      return UserService.vaildSmsOTP({ mobile: this.mobile, code: value, secureWord: ls.get('secureWord') }).then(
        response => {
          if (response.success) {
            this.otp = value
            this.success = true
            this.showTimer = false
            this.stopTrying = false
            this.validtrueMessge = response.message
          } else {
            this.validMessge = response.message
          }
          return {
            valid: response.success,
            data: {
              message: response.message,
            },
          }
        },
        error => {
          this.success = false
          this.validMessge = error.response.data.message
          this.stopTrying = error.response.data.data.stopTrying
          return {
            valid: false,
            data: {
              message: error.response.data.message,
            },
          }
        },
      )
    }
    extend('confrimMobile', {
      params: ['confirmation'],
      validate(value, { confirmation }) {
        return confirmation != value
      },

      message: 'Current mobile number and new mobile number is same',
    })
    extend('uniqueMobile', {
      validate: isUniqueMobile,
      message: 'Mobile number already exits',
    }, {
      immediate: false,
    })
    extend('startWith', {
      validate: value => {
        if (this.strncmp(value, '60', 2) !== 0 && this.strncmp(value, '01', 2) !== 0) {
          return false
        }
        return true
      },

      message: 'This mobile must start with 60 or 01',

    })
    extend('validOtp', {
      validate: validateOtp,
      message: '',
    }, {
      immediate: false,
    })
  },
  methods: {

    strncmp(str1, str2, n) {
      str1 = str1.substring(0, n)
      str2 = str2.substring(0, n)
      return ((str1 == str2) ? 0 : ((str1 > str2) ? 1 : -1))
    },
    handleCountdownEnd() {
      this.stopTrying = 1
      this.showTimer = 0
    },
    async  requetOTP() {
      const isValid = await this.$refs.mobileprovider.validate()
      if (isValid.valid) {
        this.loading = true
        UserService.requestOTP({ mobile: this.mobile }).then(
          response => {
            this.error = false
            const ls = new SecureLS({
              encodingType: 'aes',
              isCompression: false,
            })
            ls.set('secureWord', response.secureWord)
            ls.set('stopTrying', response.stopTrying)
            this.stopTrying = response.stopTrying
            this.showTimer = 1
            this.waitingTime = response.waitingTime * 1000
            this.loading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'OTP',
                text: response.message,
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          },
          error => {
            this.loading = false
            const self = this
            if (error.response.errors != undefined) {
              Object.entries(error.response.data.errors).map((value, key) => {
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error',
                    text: value[1][0],
                    icon: 'EditIcon',
                    variant: 'danger',
                  },
                })
              })
            } else {
              this.errorShow = true
              this.message = error.response.data.message
            }
          },
        )
      }
    },
    save_changes() {
      /* eslint-disable */
                    this.loading = true
                UserService.changeMobile({ 'mobile': this.mobile,'code':this.otp,'secureWord':ls.get('secureWord') }).then(
                (response) => {
                    this.otp =''
                    this.stopTrying = true
                    this.success = false
                    this.error = false
                    this.user.mobile = this.mobile
                     this.mobile=''
                     this.validtrueMessge = ''
                     this.$refs.observer.reset();
                     this.loading = false
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Email Change'",
                            text: response.message,
                            icon: 'EditIcon',
                            variant: 'success',
                        },
                    })

                },
                (error) => {
                    this.errorShow = true
                    this.success = false
                    this.stopTrying = true
                    this.loading = false
                    this.message =error.response.data.message
                }
                 );

        },
    }
};
</script>
