<template>
  <b-card no-body>
    <b-table-simple
      hover
      striped
      bordered
      responsive
      class="rounded-bottom mb-0"
    >
      <b-thead head-variant="light">
        <b-tr>
          <b-th>Provider</b-th>
          <b-th>Your Rate</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-show="showLoading"
          class="b-table-busy-slot"
        >
          <b-td colspan="11">
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle" />
              <strong>Loading...</strong>
            </div>
          </b-td>
        </b-tr>
        <template v-if="discountPlan.length>0 && !showLoading">
          <template
            v-for="(data,index) in discountPlan"
          >
            <b-tr><b-td colspan="2"><b>{{data.name}}</b></b-td></b-tr>
            <b-tr
              v-for="(tr, indextr) in data.items"
              :key=" tr.operators"
            >
              <b-td>
                {{ tr.operator_details }}
              </b-td>
              <b-td>{{ tr.operator_commission }}</b-td>
             
            </b-tr>

          </template>

        </template>
        <template v-else-if="!showLoading">
          <b-tr rowspan="10">
            <b-td
              colspan="11"
              class="text-center"
            >
              No data avilable
            </b-td>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>
  </b-card>
</template>
<script>
import {
  BRow, BCol, BTabs, BTab, BThead, BTr, BTh, BTd, BTbody, BTfoot, BTableSimple, BSpinner, BCard,
} from 'bootstrap-vue'
import UserService from '../../services/user.service'
import JwtService from '../../common/jwt.service'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTabs,
    BTab,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BTableSimple,
    BSpinner,
  },
  data() {
    return {
      showLoading: false,
      discountPlan: [],
    }
  },
  mounted() {
    this.getDiscountPlan()
  },
  methods: {
    getDiscountPlan() {
      this.showLoading = true
      UserService.getUserDiscountPlan().then(
        response => {
          this.discountPlan = response.Operator_DiscountChart
          this.showLoading = false
        },
        error => {
          this.showLoading = false
        },
      )
    },
  },
}

</script>
