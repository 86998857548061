<template>
  <div id="page-user-view">
    <div
      v-if="user"
      id="user-data"
    >
      <b-row>
        <b-col>
          <b-card no-body>

            <b-card-header class="pb-50">
              <h4>
               Profile
              </h4>
              <b-button
                v-if="user.type=='Software'"
                variant="primary"
                class="btn-icon  text-right"
                @click="$router.push('/profile/edit')"
              >Edit Profile
              </b-button>
            </b-card-header>
            <b-table-simple
              hover
              responsive
              class="rounded-bottom mb-0"
            >
              <b-tr>
                <b-td>Login ID</b-td>
                <b-td>{{ user.loginid }}</b-td>
              </b-tr>
              <b-tr>
                <b-td>Full Name</b-td>
                <b-td>{{ user.name }}</b-td>
              </b-tr>
              <b-tr>
                <b-td>Level/Rank</b-td>
                <b-td>{{ user.rank_name }}</b-td>
              </b-tr>
              <b-tr>
                <b-td>Discount Plan Name</b-td>
                <b-td>{{ user.slab_name }}</b-td>
              </b-tr>
              <b-tr v-if="user.firm_name != ''">
                <b-td>Company Name</b-td>
                <b-td>{{ user.firm_name }}</b-td>
              </b-tr>
              <b-tr>
                <b-td>Email</b-td>
                <b-td>{{ user.email }}</b-td>
              </b-tr>
              <b-tr>
                <b-td>Phone No.</b-td>
                <b-td>{{ user.mobile }}</b-td>
              </b-tr>
              <b-tr>
                <b-td>Address</b-td>
                <b-td>{{ user.fullAdress }}</b-td>
              </b-tr>
            </b-table-simple>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import {
  BRow, BCol, BThead, BTr, BTh, BTd, BTbody, BTfoot, BTableSimple, BButton, BCard, BCardHeader, BCardBody,
} from 'bootstrap-vue'
import UserService from '../../services/user.service'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BTableSimple,
    BButton,
    BCardHeader,
    BCardBody,
  },
  props: ["user"],
};

</script>
