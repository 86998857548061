<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab active>
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Profile</span>
      </template>
      <user-settings-general :user='user'/>
    </b-tab>
    <b-tab v-if="user.type=='Software'">
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Change Password</span>
      </template>
      <user-settings-change-password/>
    </b-tab>
    <b-tab v-if="user.type=='Software'">
      <template #title>
        <feather-icon
          icon="PhoneIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Change Mobile Number</span>
      </template>
      <user-setting-mobile-change :user='user'/>
    </b-tab>
    <b-tab v-if="user.type=='Software'">
      <template #title>
        <feather-icon
          icon="MailIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Change Email</span>
      </template>
      <user-setting-email-change :user='user'/>
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon
          icon="PieChartIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">My Discount Chart</span>
      </template>
      <user-discount-plan />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon
          icon="SettingsIcon"
          size="18"
          class="mr-50"

        />
        <span class="font-weight-bold">Settings</span>
      </template>
      <user-setting  :user='user'/>
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import UserSettingsGeneral from './UserSettingsGeneral.vue'
import UserSetting from './UserSetting.vue'
import UserSettingsChangePassword from './UserSettingsChangePassword.vue'
import UserSettingMobileChange from './UserSettingMobileChange.vue'
import UserSettingEmailChange from './UserSettingEmailChange.vue'
import UserDiscountPlan from './UserDiscountPlan.vue'
import UserService from '../../services/user.service'

export default {
  components: {
    UserSettingsGeneral,
    UserSetting,
    UserSettingsChangePassword,
    UserSettingEmailChange,
    UserSettingMobileChange,
    UserDiscountPlan,
    BTabs,
    BTab,
  },
  data() {
    return {
      user: '',
    }
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    getUserInfo() {
      UserService.getUserInfo().then(
        response => {
          this.user = response
        },
        error => {},
      )
    },
  },
}
</script>
